/*
 * @Author: duchengdong
 * @Date: 2021-10-27 10:28:45
 * @LastEditors: Conner
 * @LastEditTime: 2022-08-11 17:05:22
 * @Description: 路由配置文件
 */
import AuthLayout, { BlankLayout } from 'layout/AuthLayout'
import { lazy } from 'react'

const RouteConfig = [
    {
        path: '/login',
        component: lazy(() => import('pages/Login')),
    },
    { path: '/', redirect: '/operation' , exact: true},
    {
        path: '/',
        component: AuthLayout,
        children: [
            {
                name: '工作台',
                path: '/home',
                component: BlankLayout,
                children: [
                    {
                        name: '仪表盘',
                        path: '/home/dashboard',
                        component: lazy(() => import('pages/HomeWork/DashEcharts')),
                    },
                    {
                        name: '我的待办',
                        path: '/home/worktodo',
                        component: lazy(() => import('pages/HomeWork/WorkTodo')),
                    },
                    {
                        name: '我的已办',
                        path: '/home/workalready',
                        component: lazy(() => import('pages/HomeWork/WorkAlready')),
                    },
                ],
            },
            {
                name: '运营',
                path: '/operation',
                component: BlankLayout,
                children: [
                    {
                        name: '患者详情',
                        path: `/operation/patient/patientmanage/detail/:id/:userId`,
                        component: lazy(() => import('pages/PatientService/PatientManage/PatientDetail')),
                    },
                    {
                        name: '患者管理',
                        path: '/operation/patient/patientmanage',
                        component: lazy(() => import('pages/PatientService/PatientManage')),
                    },
                    {
                        name: '用户管理详情',
                        path: `/operation/patient/usermanage/detail/:id?`,
                        component: lazy(() => import('pages/PatientService/UserManage/UserDetail')),
                    },
                    {
                        name: '用户管理',
                        path: '/operation/patient/usermanage',
                        component: lazy(() => import('pages/PatientService/UserManage')),
                    },
                    {
                        name: '服务单详情',
                        path: `/operation/patient/serviceorder/detail/:id?`,
                        component: lazy(() => import('pages/PatientService/ServiceOrder/ServiceDetail')),
                    },
                    {
                        name: '服务单管理',
                        path: '/operation/patient/serviceorder',
                        component: lazy(() => import('pages/PatientService/ServiceOrder')),
                    },
                    {
                        name: '医疗机构/编辑/新增',
                        path: '/operation/medical/medicalmanage/edit/:type/:id',
                        component: lazy(() => import('pages/MedicalService/MedicalManage/MedicalEdit')),
                    },
                    {
                        name: '科室列表',
                        path: '/operation/medical/medicalmanage/departmentlist/:tname/:id',
                        component: lazy(() => import('pages/MedicalService/MedicalManage/DepartmentList')),
                    },
                    {
                        name: '医疗机构',
                        path: '/operation/medical/medicalmanage',
                        component: lazy(() => import('pages/MedicalService/MedicalManage')),
                    },
                    {
                        name: '医生管理详情/编辑',
                        path: `/operation/medical/doctormanage/:type/:id?`,
                        component: lazy(() => import('pages/MedicalService/DoctorManage/DoctorDetalis')),
                    },
                    {
                        name: '医生管理',
                        path: '/operation/medical/doctormanage',
                        component: lazy(() => import('pages/MedicalService/DoctorManage')),
                    },
                    {
                        name: '角色管理',
                        path: '/operation/set/rolemanage',
                        component: lazy(() => import('pages/ProjectSettings/RoleManage')),
                    },
                    {
                        name: '成员管理',
                        path: '/operation/set/membermanage',
                        component: lazy(() => import('pages/ProjectSettings/MemberManage')),
                    },
                    {
                        path: '/operation/content/articlecontent/edit',
                        name: '文章管理编辑',
                        component: lazy(() => import('pages/ContentManage/ArticleContent/AddArticle')),
                    },
                    {
                        name: '文章管理',
                        path: '/operation/content/articlecontent',
                        component: lazy(() => import('pages/ContentManage/ArticleContent')),
                    },
                    {
                        path: '/operation/content/videocontent/edit',
                        name: '课程内容编辑',
                        component: lazy(() => import('pages/ContentManage/VideoContent/AddCourse')),
                    },
                    {
                        name: '课程内容',
                        path: '/operation/content/videocontent',
                        component: lazy(() => import('pages/ContentManage/VideoContent')),
                    },
                    {
                        name: '课程内容',
                        path: '/operation/content/videocontent',
                        component: lazy(() => import('pages/ContentManage/VideoContent')),
                    },
                    {
                        name: '角色管理',
                        path: '/operation/set/rolemanage',
                        component: lazy(() => import('pages/ProjectSettings/RoleManage')),
                    },
                    {
                        name: '成员管理',
                        path: '/operation/set/membermanage',
                        component: lazy(() => import('pages/ProjectSettings/MemberManage')),
                    },
                    {
                        name: '测试',
                        path: '/operation/test',
                        component: lazy(() => import('pages/Test')),
                    },
                ],
            },
        ],
    },
    
]

export const initRouteConfig = [
    {
        path: '/login',
        component: lazy(() => import('pages/Login')),
    },
]

export default RouteConfig
