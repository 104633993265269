/*
 * @Author: yangmiaomiao
 * @Date: 2021-11-06 16:46:56
 * @LastEditors: Conner
 * @LastEditTime: 2022-08-17 17:30:03
 * @Description:医疗服务
 */

import http from 'utils/request'
import Mock from 'mockjs'
import { PageInfo, ResObj } from 'types/config.type.d'

//1医疗机构列表
export const getMedicalList = (pageInfo: PageInfo, searchData: ResObj) => {
    return http({
        url: '/hospital/bac/hospital/page',
        method: 'post',
        data: {
            ...pageInfo,
            ...searchData,
        },
    })
}

//2医疗机构详情
export const medicalDetails = (id: string) => {
    return http({
        url: `/hospital/bac/hospital/detail/${id}`,
        method: 'get',
    })
}

//4新增机构/编辑机构
export const saveMedical = (data) => {
    return http({
        url: `/hospital/bac/hospital`,
        method: 'post',
        data,
    })
}
//删除机构
export const deleteMedical = (id: string) => {
    return http({
        url: `/hospital/bac/hospital/${id}`,
        method: 'delete',
    })
}

//3科室列表
export const getDepartmentList = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/hospital/bac/department/list',
        method: 'post',
        data: {
            ...pageInfo,
            ...data,
        },
    })
}
//4新增+编辑 一级科室 二级科室
export const saveDepartment = (data) => {
    return http({
        url: `/hospital/bac/department`,
        method: 'post',
        data,
    })
}

//5删除科室
export const deleteDepartment = (id: string) => {
    return http({
        url: `/hospital/bac/department/${id}`,
        method: 'delete',
    })
}

//诊疗科目
export const diagnosisListApi = (data) => {
    return http({
        url: `/hospital/bac/department/page`,
        method: 'POST',
        data:data,
    })
}

//6医生管理列表
export const getDoctorList = (pageInfo: PageInfo, searchData: ResObj) => {
    return http({
        url: '/hospital/bac/doctor/page',
        method: 'post',
        data: {
            ...pageInfo,
            ...searchData,
        },
    })
}

//7修改医生状态、删除医生 status:1正常 2禁用 3删除
export const isStartUsingDoctor = (data: ResObj) => {
    return http({
        url: `/hospital/bac/doctor?id=${data.id}&status=${data.status}`,
        method: 'put',
    })
}

//8.1添加医生 !!!!
export const addDoctor = (data: ResObj) => {
    return http({
        url: `/hospital/bac/doctor/registry/doctor`,
        method: 'post',
        data,
    })
}
//8.2编辑医生
export const editDoctor = (data: ResObj) => {
    return http({
        url: `/hospital/bac/doctor/basic`,
        method: 'post',
        data,
    })
}

//9医生详情-基本信息
export const doctorDetail = (id: any) => {
    return http({
        url: `/hospital/bac/doctor/detail/${id}`,
        method: 'get',
    })
}

//11医生详情-认证信息
export const doctorAudit = (id) => {
    return http({
        url: `/hospital/bac/doctor/verification/${id}`,
        method: 'get',
    })
}

//12医生详情服务列表
export const doctorServiceList = (data) => {
    return http({
        url: `/hospital/bac/doctor/inquiry/setting/${data.id}`,
        method: 'get',
    })
}
//医生详情保单列表
export const guaranteeSlipList = (data) => {
    return http({
        url: `/hospital/bac/insurances:page`,
        method: 'POST',
        data,
    })
}

//医生详情保单新建，编辑
export const guaranteeSlipAddApi = (data) => {
    return http({
        url: `/hospital/bac/insurances`,
        method: 'POST',
        data,
    })
}
//医生详情保单删除
export const guaranteeSlipDeleteApi = (id) => {
    return http({
        url: `/hospital/bac/insurances/${id}:remove`,
        method: 'POST',
    })
}
//医生详情保单获取单个保单
export const guaranteeSlipDetailsApi = (id) => {
    return http({
        url: `/hospital/bac/insurances/${id}`,
        method: 'GET',
    })
}
//13 医生认证
export const auditDoctor = (data) => {
    return http({
        url: `/hospital/bac/doctor/verify`,
        method: 'post',
        data,
    })
}

//14服务列表设置确定接口 ！！！！
export const editDoctorService = (data) => {
    return http({
        url: `/hospital/bac/editDoctorService/page?`,
        method: 'post',
        data,
    }).then((res) => {
        return Mock.mock({
            code: 100,
            msg: null,
            requestId: null,
        })
    })
}

//15服务列表上下架接口
export const isOpenService = (data) => {
    return http({
        url: `/hospital/bac/doctor/inquiry/setting?id=${data.id}&status=${data.status}`,
        method: 'put',
    })
}
//平台备案接口
export const platformRecordService = (doctorId: string | number) => {
    return http({
        url: `/hospital/bac/doctor/doctors:upload`,
        method: 'POST',
        data: {doctorId}
    })
}