/*
 * @Author: yangmiaomiao
 * @Date: 2021-10-26 15:01:05
 * @LastEditors: Conner
 * @LastEditTime: 2022-08-11 17:00:29
 * @Description:
 */
import axios, { AxiosRequestConfig } from 'axios'
import { ResConfig, ResObj } from 'types/config.type'
import { getStorage } from 'utils/localStorage'
import { Logout } from 'pages/Login/logout'
import { message } from 'antd'

const API_PATH = process.env.REACT_APP_API_PATH

axios.defaults.validateStatus = () => true
axios.defaults.timeout = 60000
axios.defaults.baseURL = API_PATH
// 请求前
axios.interceptors.request.use(
    (config) => {
        // 获取token数据
        const token = getStorage('token')

        // 设置请求头
        //get请求 header中添加Content-Type
        // if (config.method === 'get') {
        //     config.data = { unused: 0 }
        // }

        config.headers = {
            Authorization: token || '',
            'Content-Type': 'application/json;charset=UTF-8;',
            ...(config.headers || {}),
        }
        return config
    },
    (error) => {},
)

// 请求后
axios.interceptors.response.use(
    (config) => {
        return config
    },
    (error) => {},
)
//eslint-disable-next-line
export default (config: AxiosRequestConfig) =>
    new Promise<ResConfig<ResObj>>((resolve) => {
        axios(config)
            .then((res: any) => {
                if (res?.data?.code === 401) {
                    //登录状态已过期
                    message.error(res?.data?.msg || '请求失败')
                    if (!window.location.href.includes('localhost')) {
                        return Logout()
                    }
                } else {
                    resolve(res?.data || {})
                }
            })
            .catch(() => {})
    })
