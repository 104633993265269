/*
 * @Author: yangmiaomiao
 * @Date: 2021-11-08 10:31:54
 * @LastEditors: Conner
 * @LastEditTime: 2022-08-16 18:02:47
 * @Description:配置列表页store
 */
import { getPatientList, getPatientUserList, getServiceOrderList } from 'service/patientApi'
import { getMedicalList, getDepartmentList, doctorServiceList, getDoctorList , guaranteeSlipList} from 'service/medicalApi'
import { getArticleList, getCourseList } from 'service/contentApi'
import { getCorpMemberList } from 'service/settingsApi'
const listStoreConfig = [
    {
        name: 'patientList',
        request_api: getPatientList,
        tip: '患者列表',
    },
    {
        name: 'patientUserList',
        request_api: getPatientUserList,
        tip: '用户管理',
    },
    {
        name: 'serviceOrderList',
        request_api: getServiceOrderList,
        tip: '服务单（问诊）列表',
    },
    {
        name: 'medicalList',
        request_api: getMedicalList,
        tip: '医疗列表',
    },
    {
        name: 'departmentList',
        request_api: getDepartmentList,
        tip: '科室列表',
    },
    {
        name: 'doctorServiceList',
        request_api: doctorServiceList,
        tip: '医生服务列表',
    },
    {
        name: 'guaranteeSlipList',
        request_api: guaranteeSlipList,
        tip: '医生服务列表',
    },
    {
        name: 'doctorList',
        request_api: getDoctorList,
        tip: '医生管理列表',
    },
    {
        name: 'articleList',
        request_api: getArticleList,
        tip: '文章列表',
    },
    {
        name: 'courseList',
        request_api: getCourseList,
        tip: '课程列表',
    },
    {
        name: 'corpMemberList',
        request_api: getCorpMemberList,
        tip: '成员管理（企业微信成员）',
    },
]

export { listStoreConfig }
