/*
 * @Author: mawenli
 * @Date: 2021-11-09 17:35:09
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2022-04-21 15:04:03
 * @Description:
 */

import http from 'utils/request'
import { PageInfo, ResObj } from 'types/config.type'

//模块下拉框数据接口
export const getModuleInfo = (id, params?: any) =>
    http({
        // url: '/system/bizDic/parentId/' + id,
        url: '/hospital/bac/bizDic/parentId/' + id,
        method: 'get',
        params,
    })

//文章管理列表
export const getArticleList = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/hospital/bac/socialarticle/list',
        method: 'post',
        data: { ...pageInfo, ...data },
    })
}

export const articleList = (data: ResObj) => {
    return http({
        url: '/hospital/bac/socialarticle/list',
        method: 'post',
        data,
    })
}

//文章详情
export const articleById = (params: any) =>
    http({
        url: '/hospital/bac/socialarticle/info',
        method: 'get',
        params,
    })

//文章保存
export const articleSave = (data: any) =>
    http({
        url: '/hospital/bac/socialarticle/save',
        method: 'post',
        data,
    })

//设置发布状态
export const setReleaseArticle = (data: any) =>
    http({
        url: '/hospital/bac/socialarticle/setReleaseStatus',
        method: 'post',
        data,
    })

//设置置顶状态
export const setTopArticle = (data: any) =>
    http({
        url: '/hospital/bac/socialarticle/setTopStatus',
        method: 'post',
        data,
    })

//讲师下拉框
export const getTeacherOptions = (data: any) =>
    http({
        url: '/hospital/bac/socialcourse/teacherList',
        method: 'post',
        data,
    })

//课程管理列表
export const getCourseList = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/hospital/bac/socialcourse/list',
        method: 'post',
        data: { ...pageInfo, ...data },
    })
}
export const CourseList = (params) => {
    return http({
        url: '/hospital/bac/socialcourse/list',
        method: 'post',
        data: params,
    })
}

//课程详情
export const CourseById = (params: any) =>
    http({
        url: '/hospital/bac/socialcourse/info',
        method: 'get',
        params,
    })

//文章保存
export const CourseSave = (data: any) =>
    http({
        url: '/hospital/bac/socialcourse/save',
        method: 'post',
        data,
    })

//设置发布状态
export const setReleaseCourse = (data: any) =>
    http({
        url: '/hospital/bac/socialcourse/setReleaseStatus',
        method: 'post',
        data,
    })

//设置置顶状态
export const setTopCourse = (data: any) =>
    http({
        url: '/hospital/bac/socialcourse/setTopStatus',
        method: 'post',
        data,
    })
