/*
 * @Author: duchengdong
 * @Date: 2021-10-12 14:21:16
 * @LastEditors: Conner
 * @LastEditTime: 2022-08-11 17:21:08
 * @Description:
 */
import { useState, useEffect, Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import useStoreHooks from 'hooks/useStoreHooks'
import LoadingHtmp from 'layout/LoadingHtmp'
// import { Logout } from 'pages/Login/logout'
import { initRouteConfig } from './routes/router.config'
import RouteView, { IRouteViewProps } from './routes/RouteView'
// import { getStorage } from 'utils/localStorage'
import { queryMenuList } from 'service/publicApi'
import { naviData } from './layout/datas'
import { storeDispatch, storeActions } from 'store'
import { NavRoutesFunc } from 'utils/pagesFunc'
const { saveNavMenuList } = storeActions['globle']

function App() {
    const [routeArr, setRouteArr] = useState<IRouteViewProps[]>(initRouteConfig)
    const [state] = useStoreHooks('globle')
    // const { currentProject, userInfo, projectList, navMenuList } = state //redux-state值
    const { currentProject} = state //redux-state值
    const notAppEffect = [process.env.REACT_APP_loginPath, process.env.REACT_APP_errorPath]

    useEffect(() => {
        storeDispatch(saveNavMenuList(naviData.data))
        let routes = NavRoutesFunc(naviData.data)
        setRouteArr(routes)

        if (notEffect()) {
            console.log('app=正常渲染')
            // if (window.location.href.includes('localhost')) {
            //     // 存储个本地调试token ,过期之后需要重新获取替换
            //     const token = 'aac85800-525d-4290-b324-61fb9f3b0d29'
            //     setStorage('token', token)
            // }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (notEffect()) {
            //监听获取项目数据，请求菜单数据
            if (Object.keys(currentProject).length > 0) {
                console.log('监听切换项目、重新请求菜单数据')
                const { id } = currentProject
                queryMenuList(id).then((res) => {
                    setRouteArr(res.routes)
                })
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProject])

    function notEffect() {
        let flag = true
        if (notAppEffect.findIndex((v) => v === window.location.pathname) !== -1) {
            console.log('app=登录页面不走该渲染')
            //登录页面不走该渲染
            flag = false
            return
        }
        return flag
    }

    //刷新token+user
    // let timer = useRef<any>(null)
    // function refreshToken() {
    //     timer?.current && clearInterval(timer.current)
    //     timer.current = setInterval(() => {
    //         onRefreshToken()
    //     }, 1000 * 60 * 60 * 30) //30分钟刷新一次
    // }

    return (
        <Router>
            <Suspense fallback={<LoadingHtmp />}>
                <RouteView children={routeArr} />
            </Suspense>
        </Router>
    )
}

export default App
