/*
 * @Author: zhicheng.yao
 * @Date: 2021-10-20 10:40:14
 * @Last Modified by: zhicheng.yao
 * @Last Modified time: 2021-11-06 13:44:33
 */
export const naviData = {
    code: 100,
    msg: '成功',
    data: [
        // {
        //     id: 1,
        //     parentCode: 'APP',
        //     menuCode: 'home',
        //     name: '工作台',
        //     uri: '/home',
        //     children: [
        //         {
        //             id: 11,
        //             name: '仪表盘',
        //             parentCode: 'APP',
        //             menuCode: 'home',
        //             icon: 'icon-yibiaopan',
        //             uri: '/home/dashboard',
        //         },
        //         {
        //             id: 12,
        //             name: '我的待办',
        //             parentCode: 'APP',
        //             menuCode: 'home',
        //             icon: 'icon-wodedaiban',
        //             uri: '/home/worktodo',
        //         },
        //         {
        //             id: 13,
        //             name: '我的已办',
        //             parentCode: 'APP',
        //             menuCode: 'home',
        //             icon: 'icon-wodeyiban',
        //             uri: '/home/workalready',
        //         },
        //     ],
        // },
        {
            id: 1069,
            parentCode: 'operation',
            parentId: 0,
            menuCode: 'operation',
            name: '运营',
            uri: '/operation',
            icon: '#',
            children: [
                {
                    id: 1070,
                    parentCode: 'operation',
                    parentId: 1069,
                    menuCode: 'PatientService',
                    name: '患者服务',
                    uri: '/operation/patient',
                    icon: 'icon-huanzhefuwu',
                    children: [
                        {
                            id: 1071,
                            parentCode: 'PatientService',
                            parentId: 1070,
                            menuCode: 'PatientManage',
                            name: '患者管理',
                            uri: '/operation/patient/patientmanage',
                            icon: '#',
                            children: [],
                        },
                        {
                            id: 1072,
                            parentCode: 'PatientService',
                            parentId: 1070,
                            menuCode: 'usermanage',
                            name: '用户管理',
                            uri: '/operation/patient/usermanage',
                            icon: '#',
                            children: [],
                        },
                        {
                            id: 1073,
                            parentCode: 'PatientService',
                            parentId: 1070,
                            menuCode: 'serviceorder',
                            name: '服务单管理',
                            uri: '/operation/patient/serviceorder',
                            icon: '#',
                            children: [],
                        },
                    ],
                },
                {
                    id: 1074,
                    parentCode: 'operation',
                    parentId: 1069,
                    menuCode: 'medicalservice',
                    name: '医疗服务',
                    uri: '/operation/medical',
                    icon: 'icon-yiliaofuwu',
                    children: [
                        {
                            id: 1075,
                            parentCode: 'medicalservice',
                            parentId: 1074,
                            menuCode: 'MedicalManage',
                            name: '医疗机构',
                            uri: '/operation/medical/medicalmanage',
                            icon: '#',
                            children: [],
                        },
                        {
                            id: 1076,
                            parentCode: 'medicalservice',
                            parentId: 1074,
                            menuCode: 'DoctorManage',
                            name: '医生管理',
                            uri: '/operation/medical/doctormanage',
                            icon: '#',
                            children: [],
                        },
                    ],
                },
                {
                    id: 1077,
                    parentCode: 'operation',
                    parentId: 1069,
                    menuCode: 'contentmanage',
                    name: '内容管理',
                    uri: '/operation/content',
                    icon: 'icon-neirongguanli',
                    children: [
                        {
                            id: 1078,
                            parentCode: 'contentmanage',
                            parentId: 1077,
                            menuCode: 'ArticleContent',
                            name: '文章管理',
                            uri: '/operation/content/articlecontent',
                            icon: '#',
                            children: [],
                        },
                        {
                            id: 1079,
                            parentCode: 'contentmanage',
                            parentId: 1077,
                            menuCode: 'VideoContent',
                            name: '课程管理',
                            uri: '/operation/content/videocontent',
                            icon: '#',
                            children: [],
                        },
                    ],
                },
            ],
        },
    ],
    requestId: null,
    pageInfo: null,
}

export const projectData = {
    code: 100,
    msg: '成功',
    data: [
        {
            id: 1,
            deleted: null,
            createTime: '2021-11-17T10:27:11.000+08:00',
            updateTime: '2021-11-17T10:27:11.000+08:00',
            createUser: null,
            updateUser: null,
            projectName: '景栗医疗',
            companyId: 1,
            icon: 'icon-project-yiliao',
        },
        {
            id: 2,
            deleted: null,
            createTime: '2021-11-17T10:27:11.000+08:00',
            updateTime: '2021-11-17T10:27:11.000+08:00',
            createUser: null,
            updateUser: null,
            projectName: '栗子控糖助手',
            companyId: 1,
            icon: 'icon-project-kongtang',
        },
        {
            id: 3,
            deleted: null,
            createTime: '2021-11-17T10:27:11.000+08:00',
            updateTime: '2021-11-17T10:27:11.000+08:00',
            createUser: null,
            updateUser: null,
            projectName: '暖相随',
            companyId: 1,
            icon: 'icon-project-nuanxiangsui',
        },
    ],
    requestId: null,
    pageInfo: null,
}

export const userInfoData = {
    code: 100,
    msg: '成功',
    data: {
        access_token: '2d7369a4-e45c-46f6-b47b-bbc03f16810b',
        expires_in: 7200,
        userinfo: {
            token: '2d7369a4-e45c-46f6-b47b-bbc03f16810b',
            corpId: 'ww106d0e6b0ab94c92',
            userid: 1807,
            appUserId: null,
            appId: null,
            username: 'JiaYuHai',
            headicon: null,
            mobile: null,
            loginTime: 1638166046024,
            expireTime: 1638173246024,
            ipaddr: '223.166.86.79',
            role: null,
            permissions: [],
            roles: [],
            sysUser: {
                searchValue: null,
                createBy: '',
                createTime: null,
                updateBy: null,
                updateTime: null,
                postscript: null,
                params: {},
                userId: 1807,
                deptId: null,
                userName: 'JiaYuHai',
                nickName: '海平面',
                email: '',
                phonenumber: '',
                sex: '0',
                avatar: '',
                password: 'ww106d0e6b0ab94c92',
                status: '1',
                delFlag: '0',
                loginIp: '',
                loginDate: null,
                dept: null,
                roles: [],
                roleIds: null,
                postIds: null,
                roleId: null,
                unionId: null,
                openUserid: null,
                corpId: 'ww106d0e6b0ab94c92',
                qrCode: null,
                address: null,
                thumbAvatar: null,
                position: null,
                admin: false,
            },
            projects: '[]',
            data: null,
        },
    },
    requestId: null,
    pageInfo: null,
}

//公司组织架构
export const DepartmentsList = {
    code: 100,
    msg: '成功',
    data: [
        {
            id: 1209636,
            corpId: 'ww106d0e6b0ab94c92',
            departmentId: 1,
            deptName: '上海风栗信息科技有限公司',
            deptNameEn: null,
            parentId: 0,
            orderNo: 100000000,
            status: 1,
            childDepartments: [
                {
                    id: 1209638,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 4,
                    deptName: '风栗',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99998000,
                    status: 1,
                    childDepartments: [
                        {
                            id: 1209642,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 11,
                            deptName: '技术部名字变更',
                            deptNameEn: null,
                            parentId: 4,
                            orderNo: 99998000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209653,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 26,
                            deptName: '测试部',
                            deptNameEn: null,
                            parentId: 4,
                            orderNo: 99997000,
                            status: 1,
                            childDepartments: [
                                {
                                    id: 1209651,
                                    corpId: 'ww106d0e6b0ab94c92',
                                    departmentId: 24,
                                    deptName: 'Nora',
                                    deptNameEn: null,
                                    parentId: 26,
                                    orderNo: 100000000,
                                    status: 1,
                                    childDepartments: [],
                                },
                            ],
                        },
                        {
                            id: 1209721,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 98,
                            deptName: '医疗运营组',
                            deptNameEn: null,
                            parentId: 4,
                            orderNo: 99996000,
                            status: 1,
                            childDepartments: [],
                        },
                    ],
                },
                {
                    id: 1209640,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 7,
                    deptName: '外部客户',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99995000,
                    status: 1,
                    childDepartments: [
                        {
                            id: 1209637,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 3,
                            deptName: '爱他美',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 100002000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209639,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 6,
                            deptName: '复星',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 100000000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209641,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 10,
                            deptName: '达能',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99997000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209643,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 12,
                            deptName: '小红包',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99996000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209646,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 15,
                            deptName: '光明',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99995000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209647,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 16,
                            deptName: '星巴克',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99994000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209648,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 19,
                            deptName: '美特好',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99991000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209649,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 22,
                            deptName: '银座',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99988000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209654,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 27,
                            deptName: '美德乐',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99987000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209655,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 28,
                            deptName: '江南布衣',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99986000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209657,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 30,
                            deptName: '中商网络',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99985000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209661,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 36,
                            deptName: '测试',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99988500,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209682,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 57,
                            deptName: '沃尔玛',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99984000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209683,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 58,
                            deptName: '麦当劳',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99983000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209697,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 73,
                            deptName: '复星医疗',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99981000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209704,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 81,
                            deptName: '百盛',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99979000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209705,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 82,
                            deptName: 'OSIM',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99978000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209706,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 83,
                            deptName: '人瑞',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99977000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209707,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 84,
                            deptName: '和府',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99976000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209708,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 85,
                            deptName: '万达信息',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99975000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209709,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 86,
                            deptName: '普惠健康',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99974000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209710,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 87,
                            deptName: '康哲医药',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99973000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209711,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 88,
                            deptName: '泉源堂',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99972000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209712,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 89,
                            deptName: 'cemoy',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99971000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209720,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 97,
                            deptName: '慢逆健康',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99970000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209722,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 99,
                            deptName: '罗莱',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99969000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209723,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 100,
                            deptName: '腾讯广告',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99968000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209727,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 104,
                            deptName: '复云健康',
                            deptNameEn: null,
                            parentId: 7,
                            orderNo: 99967000,
                            status: 1,
                            childDepartments: [],
                        },
                    ],
                },
                {
                    id: 1209650,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 23,
                    deptName: '测试',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99994000,
                    status: 1,
                    childDepartments: [
                        {
                            id: 1209644,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 13,
                            deptName: '夏康team',
                            deptNameEn: null,
                            parentId: 23,
                            orderNo: 100000000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209645,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 14,
                            deptName: '周梓健team',
                            deptNameEn: null,
                            parentId: 23,
                            orderNo: 100001000,
                            status: 1,
                            childDepartments: [],
                        },
                        {
                            id: 1209652,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 25,
                            deptName: '微信广告测试',
                            deptNameEn: null,
                            parentId: 23,
                            orderNo: 99998000,
                            status: 1,
                            childDepartments: [],
                        },
                    ],
                },
                {
                    id: 1209656,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 29,
                    deptName: '325测试同步',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99993000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209658,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 33,
                    deptName: '测试lr',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99992000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209659,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 34,
                    deptName: '广告链路',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99991000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209660,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 35,
                    deptName: 'lr正常微信广告测试',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99990000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209662,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 37,
                    deptName: '品效营销官',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99989000,
                    status: 1,
                    childDepartments: [
                        {
                            id: 1209663,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 38,
                            deptName: '北一',
                            deptNameEn: null,
                            parentId: 37,
                            orderNo: 100000000,
                            status: 1,
                            childDepartments: [
                                {
                                    id: 1209672,
                                    corpId: 'ww106d0e6b0ab94c92',
                                    departmentId: 47,
                                    deptName: '新动力智联（北京）数字技术有限公司',
                                    deptNameEn: null,
                                    parentId: 38,
                                    orderNo: 100000000,
                                    status: 1,
                                    childDepartments: [],
                                },
                            ],
                        },
                        {
                            id: 1209664,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 39,
                            deptName: '北二',
                            deptNameEn: null,
                            parentId: 37,
                            orderNo: 99999000,
                            status: 1,
                            childDepartments: [
                                {
                                    id: 1209673,
                                    corpId: 'ww106d0e6b0ab94c92',
                                    departmentId: 48,
                                    deptName: '北京新广互动网络科技有限公司',
                                    deptNameEn: null,
                                    parentId: 39,
                                    orderNo: 100000000,
                                    status: 1,
                                    childDepartments: [],
                                },
                            ],
                        },
                        {
                            id: 1209665,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 40,
                            deptName: '北三',
                            deptNameEn: null,
                            parentId: 37,
                            orderNo: 99998000,
                            status: 1,
                            childDepartments: [
                                {
                                    id: 1209674,
                                    corpId: 'ww106d0e6b0ab94c92',
                                    departmentId: 49,
                                    deptName: '沈阳天一智合传媒广告有限公司',
                                    deptNameEn: null,
                                    parentId: 40,
                                    orderNo: 100000000,
                                    status: 1,
                                    childDepartments: [],
                                },
                            ],
                        },
                        {
                            id: 1209666,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 41,
                            deptName: '东一',
                            deptNameEn: null,
                            parentId: 37,
                            orderNo: 99997000,
                            status: 1,
                            childDepartments: [
                                {
                                    id: 1209675,
                                    corpId: 'ww106d0e6b0ab94c92',
                                    departmentId: 50,
                                    deptName: '蔚蓝云创(上海)数字科技有限公司',
                                    deptNameEn: null,
                                    parentId: 41,
                                    orderNo: 100000000,
                                    status: 1,
                                    childDepartments: [],
                                },
                            ],
                        },
                        {
                            id: 1209667,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 42,
                            deptName: '东二',
                            deptNameEn: null,
                            parentId: 37,
                            orderNo: 99996000,
                            status: 1,
                            childDepartments: [
                                {
                                    id: 1209676,
                                    corpId: 'ww106d0e6b0ab94c92',
                                    departmentId: 51,
                                    deptName: '浙江沃天互动数字科技有限公司',
                                    deptNameEn: null,
                                    parentId: 42,
                                    orderNo: 100000000,
                                    status: 1,
                                    childDepartments: [],
                                },
                            ],
                        },
                        {
                            id: 1209668,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 43,
                            deptName: '西区',
                            deptNameEn: null,
                            parentId: 37,
                            orderNo: 99995000,
                            status: 1,
                            childDepartments: [
                                {
                                    id: 1209677,
                                    corpId: 'ww106d0e6b0ab94c92',
                                    departmentId: 52,
                                    deptName: '北京壹伍壹拾广告有限公司',
                                    deptNameEn: null,
                                    parentId: 43,
                                    orderNo: 100000000,
                                    status: 1,
                                    childDepartments: [],
                                },
                            ],
                        },
                        {
                            id: 1209669,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 44,
                            deptName: '南一',
                            deptNameEn: null,
                            parentId: 37,
                            orderNo: 99994000,
                            status: 1,
                            childDepartments: [
                                {
                                    id: 1209678,
                                    corpId: 'ww106d0e6b0ab94c92',
                                    departmentId: 53,
                                    deptName: '北京北岸中通网络科技有限公司',
                                    deptNameEn: null,
                                    parentId: 44,
                                    orderNo: 100000000,
                                    status: 1,
                                    childDepartments: [],
                                },
                            ],
                        },
                        {
                            id: 1209670,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 45,
                            deptName: '南二',
                            deptNameEn: null,
                            parentId: 37,
                            orderNo: 99993000,
                            status: 1,
                            childDepartments: [
                                {
                                    id: 1209679,
                                    corpId: 'ww106d0e6b0ab94c92',
                                    departmentId: 54,
                                    deptName: '合合传媒（湖南）有限公司',
                                    deptNameEn: null,
                                    parentId: 45,
                                    orderNo: 100000000,
                                    status: 1,
                                    childDepartments: [],
                                },
                            ],
                        },
                        {
                            id: 1209671,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 46,
                            deptName: '腾讯广告',
                            deptNameEn: null,
                            parentId: 37,
                            orderNo: 99992000,
                            status: 1,
                            childDepartments: [],
                        },
                    ],
                },
                {
                    id: 1209680,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 55,
                    deptName: '产研测试专用',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99988000,
                    status: 1,
                    childDepartments: [
                        {
                            id: 1209713,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 90,
                            deptName: '子部门1茜茜',
                            deptNameEn: null,
                            parentId: 55,
                            orderNo: 100000000,
                            status: 1,
                            childDepartments: [
                                {
                                    id: 1209714,
                                    corpId: 'ww106d0e6b0ab94c92',
                                    departmentId: 91,
                                    deptName: '子部门2子乡',
                                    deptNameEn: null,
                                    parentId: 90,
                                    orderNo: 100000000,
                                    status: 1,
                                    childDepartments: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 1209681,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 56,
                    deptName: '产品',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99987000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209684,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 60,
                    deptName: '用户组',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99986000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209685,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 61,
                    deptName: '欢迎语',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99985000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209686,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 62,
                    deptName: '达能测试',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99984000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209687,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 63,
                    deptName: '惠氏测试',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99983000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209688,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 64,
                    deptName: '惠氏测试广告渠道',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99982000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209689,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 65,
                    deptName: '高级渠道部门',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99981000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209690,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 66,
                    deptName: '微信测试部门1',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99980000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209691,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 67,
                    deptName: '微信测试部门2',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99979000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209692,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 68,
                    deptName: '微信测试部门3',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99978000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209693,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 69,
                    deptName: '微信测试部门4',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99977000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209694,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 70,
                    deptName: '微信测试部门5',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99976000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209695,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 71,
                    deptName: '微信测试部门6',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99975000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209696,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 72,
                    deptName: '微信测试部门7',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99974000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209699,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 74,
                    deptName: '腾讯公司',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99973000,
                    status: 1,
                    childDepartments: [
                        {
                            id: 1209698,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 75,
                            deptName: '广州研发部',
                            deptNameEn: null,
                            parentId: 74,
                            orderNo: 100000000,
                            status: 1,
                            childDepartments: [],
                        },
                    ],
                },
                {
                    id: 1209700,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 77,
                    deptName: '0713测试部1',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99972000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209701,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 78,
                    deptName: '0713测试部2',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99971000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209702,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 79,
                    deptName: '0713测试部3',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99970000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209703,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 80,
                    deptName: '0714测试部4',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99969000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209715,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 92,
                    deptName: '829Test专用',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99968000,
                    status: 1,
                    childDepartments: [
                        {
                            id: 1209716,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 93,
                            deptName: '830Test子部门',
                            deptNameEn: null,
                            parentId: 92,
                            orderNo: 100000000,
                            status: 1,
                            childDepartments: [],
                        },
                    ],
                },
                {
                    id: 1209717,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 94,
                    deptName: 'jerry唯一部门',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99967000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209718,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 95,
                    deptName: 'jerry',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99966000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209719,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 96,
                    deptName: 'jerry2',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99965000,
                    status: 1,
                    childDepartments: [],
                },
                {
                    id: 1209724,
                    corpId: 'ww106d0e6b0ab94c92',
                    departmentId: 101,
                    deptName: 'test1',
                    deptNameEn: null,
                    parentId: 1,
                    orderNo: 99964000,
                    status: 1,
                    childDepartments: [
                        {
                            id: 1209725,
                            corpId: 'ww106d0e6b0ab94c92',
                            departmentId: 102,
                            deptName: 'test1-2',
                            deptNameEn: null,
                            parentId: 101,
                            orderNo: 100000000,
                            status: 1,
                            childDepartments: [
                                {
                                    id: 1209726,
                                    corpId: 'ww106d0e6b0ab94c92',
                                    departmentId: 103,
                                    deptName: 'test1-2-3',
                                    deptNameEn: null,
                                    parentId: 102,
                                    orderNo: 100000000,
                                    status: 1,
                                    childDepartments: [],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
    requestId: null,
    pageInfo: null,
}

//企业成员
export const CorpMemberData = {
    code: 100,
    msg: '成功',
    data: [
        {
            id: null,
            userId: '13002172190',
            corpId: 'ww106d0e6b0ab94c92',
            name: '张惺',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc4de2fd6862040657',
            avatar: 'http://wework.qpic.cn/bizmail/eiaZyLIy7FYQ6aebyQ8GNx9V9IE4iap7F7R9xlYpzS6cb9XY3JdIscAg/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTMwMDIxNzIxOTA=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '上海风栗信息科技有限公司',
            mainDepartment: 1,
        },
        {
            id: null,
            userId: '13122628869',
            corpId: 'ww106d0e6b0ab94c92',
            name: '苟玉平',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc7b768e67a8691cc7',
            avatar: 'http://wework.qpic.cn/bizmail/RCSMUKYb8u9xknJKc3kL6icqqgeupEQaNXe7rV3O2aFic5h01LL9Zc2w/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTMxMjI2Mjg4Njk=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '复星医疗',
            mainDepartment: 73,
        },
        {
            id: null,
            userId: '13480638271',
            corpId: 'ww106d0e6b0ab94c92',
            name: 'Winner Li',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc3cb6bd6753205dde',
            avatar: 'http://wework.qpic.cn/bizmail/AwG5xWemlia4a8u1ufmFvpAkRoD475c3iap45CpjDY3ra1ibiaibAm1DIIQ/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTM0ODA2MzgyNzE=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '沃尔玛',
            mainDepartment: 57,
        },
        {
            id: null,
            userId: '13654238120',
            corpId: 'ww106d0e6b0ab94c92',
            name: '张依盟',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc32b2055391e6031d',
            avatar: 'https://wework.qpic.cn/bizmail/DicZN9zxviaAAePDVib0T0siaNJ8Nr1nianlnmYy6zibZQxRy1phCzMicYv6w/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTM2NTQyMzgxMjA=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '复星医疗',
            mainDepartment: 73,
        },
        {
            id: null,
            userId: '13817704452',
            corpId: 'ww106d0e6b0ab94c92',
            name: '赵艺茹',
            alias: '926',
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc271892faf1ece733',
            avatar: 'http://wework.qpic.cn/bizmail/zNPw6NGtvYVlTCt4GgmFcN9vzpkjENW7XZRzds7Hg9DEcSqoPMAOXA/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTM4MTc3MDQ0NTI=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '产研测试专用',
            mainDepartment: 55,
        },
        {
            id: null,
            userId: '13818873384',
            corpId: 'ww106d0e6b0ab94c92',
            name: '吴胜余',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vcf541e40aa4c92129',
            avatar: 'http://wework.qpic.cn/bizmail/icPib1OkFAx0TJuEajnhR9ER97m5ATyIq15gzmd4BejBvGFYyEaJSbfQ/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTM4MTg4NzMzODQ=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '复星医疗',
            mainDepartment: 73,
        },
        {
            id: null,
            userId: '13917278403',
            corpId: 'ww106d0e6b0ab94c92',
            name: '文晒',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vcf375c7e9ad922b52',
            avatar: 'https://wework.qpic.cn/bizmail/dJsm71SDE3jhicyUtu7YGqJODG5Sx3EPan5ia8nHyH5defBx1cqMnH1w/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTM5MTcyNzg0MDM=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '复星医疗',
            mainDepartment: 73,
        },
        {
            id: null,
            userId: '15161713412',
            corpId: 'ww106d0e6b0ab94c92',
            name: '李跃',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vcf701acee1b4e79a7',
            avatar: 'http://wework.qpic.cn/bizmail/mdv5XqXhc8YEvOgNDHias1wfIiatWI9NfSBydzu6XZWVFOonkJXSF8cQ/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTUxNjE3MTM0MTI=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '复星医疗',
            mainDepartment: 73,
        },
        {
            id: null,
            userId: '15179434908',
            corpId: 'ww106d0e6b0ab94c92',
            name: '正则表达式',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vce1d34d69a1256a3c',
            avatar: 'https://wework.qpic.cn/wwhead/duc2TvpEgSTPk74IwG7Bs8ickqswfsVJ4b6U908kCEdsdkibMMzHsDNicpXXSuXnRicAwAEoiaPkRXW8/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTUxNzk0MzQ5MDg=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '产研测试专用,上海风栗信息科技有限公司',
            mainDepartment: 1,
        },
        {
            id: null,
            userId: '15801813903',
            corpId: 'ww106d0e6b0ab94c92',
            name: '陈琳娟',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vce4f66ee02ef88aa3',
            avatar: 'http://wework.qpic.cn/bizmail/Tdyzs0ibia40jHkpy1WhL1BWTqQ3RhoppicS1LtRcel5dEKgq6T9bCdzw/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTU4MDE4MTM5MDM=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '复星医疗',
            mainDepartment: 73,
        },
        {
            id: null,
            userId: '15901006542',
            corpId: 'ww106d0e6b0ab94c92',
            name: '李雪娇',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vcc3b204052ddc1741',
            avatar: 'http://wework.qpic.cn/bizmail/YLUNMoYeGVwKiaCe2nzoibuEfm2oqS1xxTJEthPI6jUv6E0icWy1A82WA/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTU5MDEwMDY1NDI=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '复星医疗',
            mainDepartment: 73,
        },
        {
            id: null,
            userId: '15967130011',
            corpId: 'ww106d0e6b0ab94c92',
            name: '吴青',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc2a7c912dcf5cfc26',
            avatar: 'http://wework.qpic.cn/bizmail/FMyeesZcEoibrBMtvicXYtdSYfJrVJqscDbmzx86QnLM09jsqSH1FzOw/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTU5NjcxMzAwMTE=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '复星医疗',
            mainDepartment: 73,
        },
        {
            id: null,
            userId: '17321177028',
            corpId: 'ww106d0e6b0ab94c92',
            name: 'summer',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc5e72e1dc37d97444',
            avatar: 'http://wework.qpic.cn/bizmail/1If6Z4cRZL4icu9ogmw9WrJ0TrBtJm4LkSnwDIlox7NvD7j85iavNhjw/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTczMjExNzcwMjg=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '用户组',
            mainDepartment: 60,
        },
        {
            id: null,
            userId: '17602158150',
            corpId: 'ww106d0e6b0ab94c92',
            name: '杨业',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc5f3bb3a2952951ab',
            avatar: 'http://wework.qpic.cn/bizmail/FwibOSfNspwP24FA8n6nyUwzUqXqVaHM3CGmra1eBtu9xj61W9XgDtQ/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTc2MDIxNTgxNTA=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '上海风栗信息科技有限公司,产研测试专用',
            mainDepartment: 1,
        },
        {
            id: null,
            userId: '17701768201',
            corpId: 'ww106d0e6b0ab94c92',
            name: '杨敏敏',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc0552f12ca62a9c8c',
            avatar: 'https://wework.qpic.cn/bizmail/sTMGgoycssiaVibXYysyicic8eqbddh7MtvqyHKv9mR9wPic36nyPLViaGeQ/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTc3MDE3NjgyMDE=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '复星医疗',
            mainDepartment: 73,
        },
        {
            id: null,
            userId: '17751753501',
            corpId: 'ww106d0e6b0ab94c92',
            name: '李梦奇',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc9cad09f714a50f54',
            avatar: 'http://wework.qpic.cn/bizmail/iazVkibh13wqco3URzRV6NjUut49yTFJskumYasydr48Tia5bpIl37icNw/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTc3NTE3NTM1MDE=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '复星医疗',
            mainDepartment: 73,
        },
        {
            id: null,
            userId: '18361072360',
            corpId: 'ww106d0e6b0ab94c92',
            name: '张阳',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc4096a6fb8f020ccd',
            avatar: 'http://wework.qpic.cn/bizmail/ibGibCEEvOuF9iaD8ArcH1eKsicwLPeNnTnT3PX31qyVicCFADJ9wxchciaw/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTgzNjEwNzIzNjA=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '风栗,技术部名字变更,test1-2-3',
            mainDepartment: 103,
        },
        {
            id: null,
            userId: '18621719035',
            corpId: 'ww106d0e6b0ab94c92',
            name: '万文裕',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc141d1fa1d41f9eb1',
            avatar: 'https://wework.qpic.cn/bizmail/b3DqjzmA8nWISApftu80ux7XAlBMgRAzdsZIH1V97BQjIjP4gmGMJw/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTg2MjE3MTkwMzU=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '中商网络',
            mainDepartment: 30,
        },
        {
            id: null,
            userId: '18698643600',
            corpId: 'ww106d0e6b0ab94c92',
            name: 'Yancey Ren',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc7a327abb9e0e339b',
            avatar: 'https://wework.qpic.cn/bizmail/ynVFewmBWMEPEcMxsdy1WLhE9pXqJ0Agk6vah2kqFbm5uksVTpFAIQ/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTg2OTg2NDM2MDA=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '沃尔玛',
            mainDepartment: 57,
        },
        {
            id: null,
            userId: '18721527618',
            corpId: 'ww106d0e6b0ab94c92',
            name: '张旭',
            alias: null,
            position: null,
            externalPosition: null,
            qrCode: 'https://open.work.weixin.qq.com/wwopen/userQRCode?vcode=vc1d6af9b5fe9b70f1',
            avatar: 'http://wework.qpic.cn/bizmail/Eiag1CCplYbKicTiaNWHmpo9TTKjJyROV75XDxugC8gWhd8xaRMAxKeFw/0',
            address: null,
            gender: 1,
            type: 1,
            status: 1,
            momentUrl: 'http://ui.qywechat.test.gemii.cc:58080/friendMoments?query_1=d3cxMDZkMGU2YjBhYjk0Yzky&query_2=MTg3MjE1Mjc2MTg=',
            userRoleList: [
                {
                    id: 509,
                    name: '上海风栗信息科技有限公司管理员',
                    status: 1,
                    type: 1,
                    scope: 1,
                    corpId: 'ww106d0e6b0ab94c92',
                    createUserId: null,
                    updateUserId: null,
                    roleDataType: null,
                    createDate: '2021-09-26T19:02:45',
                    updateDate: '2021-09-26T19:02:45',
                    reqJson: null,
                    roleDataDeptList: null,
                },
            ],
            deptIds: '复星医疗',
            mainDepartment: 73,
        },
    ],
    pageInfo: { currentPage: 1, totalPage: 14, pageSize: 20, totalRecords: 270 },
}
