/*
 * @Author: yangmiaomiao
 * @Date: 2021-11-11 11:17:09
 * @LastEditors: Conner
 * @LastEditTime: 2022-08-11 17:17:40
 * @Description:
 */
import http from 'utils/request'
import { PageInfo, ResObj } from 'types/config.type.d'
//获取公司成员列表
export const getCorpMemberList = (pageInfo: PageInfo, searchData: ResObj) => {
    return http({
        url: '/hospital/bac/user/departList',
        method: 'post',
        data: {
            ...pageInfo,
            ...searchData,
        },
    })
}
